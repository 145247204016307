import moment from "moment";
import {
	amountTypes,
	DATEFORMAT,
	DATEFORMATFRIENDLY,
	getFriendlyDateFromDateStr
} from "../../../constants";
import { InvoiceLineType } from "../configs";
import { getInvoiceForFamily } from "./getInvoiceForFamily";

export const addUnusedDaysToInvoice = (
	invoices,
	familyId,
	newCredits,
	firstMonthDateStr,
	lastMonthDateStr,
	tariffs
) => {
	if (!newCredits[familyId]) return false;

	for (let c = 0; c < newCredits[familyId].length; c++) {
		const { tariffId, validUntilDateStr, dateStr } = newCredits[familyId][c];
		if (dateStr < firstMonthDateStr) continue; // Before this month
		if (dateStr > lastMonthDateStr) continue; // After this month

		const tariff = tariffs[tariffId];
		if (!tariff) continue;

		const date = moment(dateStr, DATEFORMAT);
		const line = {
			dateStr,
			date: date.format(DATEFORMATFRIENDLY),
			dayOfWeek: date.format("dddd"),
			description: `Vaste dienst (niet opgenomen, tegoed geldig tot ${getFriendlyDateFromDateStr(
				validUntilDateStr
			)})`,
			amount: tariff.amounts[amountTypes.PerFixedDay],
			type: InvoiceLineType.SubscriptionNotUsed,
			tariffId
		};

		const addToInvoice = getInvoiceForFamily(invoices, familyId, true);
		addToInvoice.lines.push(line);
	}
};
