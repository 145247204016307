import { Dropdown } from "semantic-ui-react";
import { getSubscriptionStatusLabelComponent, subscriptionStatusOptions } from "../../constants";
import styles from "../../components/DienstenTable/index.module.css";
import { columns } from "../../components/DienstenTable/columns";
import { ENABLEVASTEPLANNING } from "../..";

const vastePlanningStatusColumn = {
	name: "status",
	label: "Status",
	getDisplayContent: (dienst) => getSubscriptionStatusLabelComponent(dienst.status),
	getSortLabel: (dienst) => dienst.status,
	filter: (search, dienst) => (search?.length > 0 ? search.includes(dienst.status) : true),
	customFilterComponent: (search, onSearchChange) => (
		<Dropdown
			name="status"
			icon="search"
			iconPosition="left"
			placeholder="Filter..."
			value={search || []}
			onChange={onSearchChange}
			selection
			search
			multiple
			options={subscriptionStatusOptions}
			className={styles.searchDropdown}
			fluid
			clearable
		/>
	)
};

export const getSubscriptionColumns = () => {
	let subscriptionColumns = columns.filter(
		(col) => !col.name.includes("reaction") && !col.name.includes("error")
	);

	// if (ENABLEVASTEPLANNING) {
	// 	subscriptionColumns = subscriptionColumns.filter((col) => col.name !== "status");
	// 	subscriptionColumns = [vastePlanningStatusColumn, ...subscriptionColumns];
	// }

	return subscriptionColumns;
};
