import { Button, Form, Modal, Table } from "semantic-ui-react";
import React, { useState } from "react";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

export const DeleteDienstModal = ({
	open,
	onClose,
	item,
	knownNanniesPerFamily,
	familiesById,
	reactions,
	selectedItemId,
	columns,
	collection
}) => {
	const [loading, setLoading] = useState(false);

	const handleConfirm = async () => {
		if (!selectedItemId) return;

		setLoading(true);

		let fbRef = firebase.firestore().collection(collection).doc(selectedItemId);
		await fbRef.delete();

		setLoading(false);
		onClose();
	};

	const content =
		item &&
		columns.map((column) => (
			<Table.Row key={column.name}>
				<Table.Cell>
					<b>{column.label}</b>
				</Table.Cell>
				<Table.Cell>
					{column.getDisplayContent(item, familiesById, knownNanniesPerFamily, reactions)}
				</Table.Cell>
			</Table.Row>
		));

	if (!item) return null;

	return (
		<Modal open={open} onClose={onClose}>
			<Modal.Header>Weet je zeker dat je deze dienst wilt verwijderen?</Modal.Header>
			<Modal.Content>
				<Form>
					<Form.Field label={"Dienst details"} style={{ marginBottom: 0 }} />
					<Table
						compact
						style={{ marginTop: 0, opacity: 0.6, backgroundColor: "rgb(250,250,250)" }}
					>
						<Table.Body>{content}</Table.Body>
					</Table>
				</Form>
			</Modal.Content>
			<Modal.Actions>
				<Button onClick={onClose}>Terug</Button>
				<Button primary onClick={handleConfirm} loading={loading}>
					Verwijder dienst
				</Button>
			</Modal.Actions>
		</Modal>
	);
};
