import React from "react";
import { Button, Segment, Table } from "semantic-ui-react";
import moment from "moment";
import styles from "./FamiliesTab.module.css";
import { CreditStatus, getCreditStatusLabel } from "../../constants";
import CreditModal from "./CreditModal";

import firebase from "firebase/app";
import "firebase/firestore";

const ManualStatus = {
	Added: 1,
	Edited: 2
};

const getManualLabel = (manual) => {
	if (!manual) return "";

	let label = "";
	if (manual.status === ManualStatus.Added) {
		label = "Toegevoegd";
	} else if (manual.status === ManualStatus.Edited) {
		label = "Gewijzigd";
	}

	label = label + " op " + manual.date;
	return label;
};

const getCellStyleForStatus = (status) => {
	if (status === CreditStatus.Used) return styles.cellStyleUsed;
	if (status === CreditStatus.Expired) return styles.cellStyleExpired;
	if (status === CreditStatus.Removed) return styles.cellStyleExpired;
	return styles.cellStyleRegular;
};

const FamilyCredits = ({ credits, name, familyId }) => {
	const [modal, setModal] = React.useState({
		open: false,
		selectedCreditIdx: -1
	});

	let totalAvailableCredits = React.useMemo(() => {
		let nrcredits = 0;
		if (credits) {
			for (let i = 0; i < credits.length; i++) {
				if (credits[i].status !== CreditStatus.Available) continue;
				nrcredits = nrcredits + 1;
			}
		}
		return nrcredits;
	}, [credits]);

	const sortedCredits = React.useMemo(() => {
		if (!credits) return [];

		let sorted = credits.map((credit, idx) => ({ ...credit, id: idx }));
		return sorted.sort((a, b) => {
			if (a.dateStr < b.dateStr) return 1;
			if (b.dateStr < a.dateStr) return -1;
			return 0;
		});
	}, [credits]);

	const onAddCredit = () => {
		setModal({
			open: true,
			selectedCreditIdx: -1
		});
	};

	const onEditCredit = (id) => {
		setModal({
			open: true,
			selectedCreditIdx: id
		});
	};

	const onCancel = () => {
		setModal({
			open: false,
			selectedCreditIdx: -1
		});
	};

	const handleSubmit = (id, data) => {
		let newCredits = credits ? [...credits] : [];
		if (id < 0) {
			// New
			newCredits.push({
				...data,
				manual: {
					status: ManualStatus.Added,
					date: moment().format("YYYY-MM-DD")
				}
			});
		} else {
			// Edit
			newCredits[id] = {
				...newCredits[id],
				status: data.status,
				usedOn: data.usedOn,
				validUntilDateStr: data.validUntilDateStr,
				manual: {
					status: ManualStatus.Edited,
					date: moment().format("YYYY-MM-DD")
				}
			};
		}

		// Send to fb
		const promise = firebase.firestore().collection("credits").doc(familyId).set({
			credits: newCredits
		});

		setModal({
			open: false,
			selectedCreditIdx: -1
		});
	};

	return (
		<Segment.Group className={styles.segmentGroup}>
			<Segment className={styles.header}>
				Tegoed van {name}
				<Button floated="right" icon="add" size="small" compact onClick={onAddCredit} />
			</Segment>
			<Segment>
				<Table selectable>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>Originele datum</Table.HeaderCell>
							<Table.HeaderCell>Status</Table.HeaderCell>
							<Table.HeaderCell>Geldig tot</Table.HeaderCell>
							<Table.HeaderCell>Gebruikt op</Table.HeaderCell>
							<Table.HeaderCell collapsing>Handmatig</Table.HeaderCell>
						</Table.Row>
					</Table.Header>

					<Table.Body>
						{sortedCredits.map((credit) => (
							<Table.Row
								className={getCellStyleForStatus(credit.status)}
								key={credit.dateStr}
								onClick={() => onEditCredit(credit.id)}
							>
								<Table.Cell>{credit.dateStr}</Table.Cell>
								<Table.Cell>{getCreditStatusLabel(credit.status)}</Table.Cell>
								<Table.Cell>{credit.validUntilDateStr}</Table.Cell>
								<Table.Cell>{credit.usedOn}</Table.Cell>
								<Table.Cell collapsing>{getManualLabel(credit.manual)}</Table.Cell>
							</Table.Row>
						))}
					</Table.Body>

					<Table.Footer>
						<Table.Row>
							<Table.HeaderCell colSpan="4">
								<b>Totaal beschikbare credits:</b>
							</Table.HeaderCell>
							<Table.HeaderCell>
								<b>{totalAvailableCredits}</b>
							</Table.HeaderCell>
						</Table.Row>
					</Table.Footer>
				</Table>
			</Segment>
			<CreditModal
				open={modal.open}
				familyId={familyId}
				familyName={name}
				credit={modal.selectedCreditIdx >= 0 ? credits[modal.selectedCreditIdx] : null}
				selectedCreditIdx={modal.selectedCreditIdx}
				onCancel={onCancel}
				onSubmit={handleSubmit}
			/>
		</Segment.Group>
	);
};

export default FamilyCredits;
