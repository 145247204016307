import moment from "moment";
import { Label } from "semantic-ui-react";

export const CREDITNRMONTHSVALID = 2;

export const CreditStatus = {
	Available: 0,
	Used: 1,
	Expired: 2,
	Removed: 3,
	Last: 4
};

export const getCreditStatusLabel = (status) => {
	switch (status) {
		case CreditStatus.Available:
			return "Beschikbaar";
		case CreditStatus.Used:
			return "Gebruikt";
		case CreditStatus.Expired:
			return "Verlopen";
		case CreditStatus.Removed:
			return "Verwijderd";
	}

	return "";
};

export const getCreditStatusOptions = () => {
	let options = [];
	for (let t = 0; t < CreditStatus.Last; t++) {
		if (t === CreditStatus.Expired) continue; // Can't pick this
		options.push({
			key: t,
			value: t,
			text: getCreditStatusLabel(t)
		});
	}
	return options;
};

export const tariffTypes = {
	Old: 0,
	Flex: 1,
	Fixed: 2,
	Last: 3
};

export const getTariffLabel = (tariff) => {
	switch (tariff) {
		case tariffTypes.Old:
			return "Oud format (betaal per dienst)";
		case tariffTypes.Flex:
			return "Flex ";
		case tariffTypes.Fixed:
			return "Vast";
	}

	return "";
};

export const getTariffOptions = () => {
	let options = [];
	for (let t = 0; t < tariffTypes.Last; t++) {
		options.push({
			key: t,
			value: t,
			text: getTariffLabel(t)
		});
	}
	return options;
};

export const amountTypes = {
	PerFixedDay: 0,
	SubscriptionCost: 1,
	Single: 2,
	LastMinute: 3,
	RegistrationFee: 4,
	Last: 5
};

export const getAmountTypeLabel = (type) => {
	switch (type) {
		case amountTypes.PerFixedDay:
			return "Per vaste dienst";
		case amountTypes.SubscriptionCost:
			return "Abonnementskosten";
		case amountTypes.Single:
			return "Enkele dienst";
		case amountTypes.LastMinute:
			return "Last-minute";
		case amountTypes.RegistrationFee:
			return "Inschrijfkosten";
	}

	return "";
};

export const enableAmountTypeForTariff = (amountType, tariffType) => {
	if (amountType === amountTypes.PerFixedDay) {
		if (tariffType === tariffTypes.Fixed) return true;
		return false;
	} else if (amountType === amountTypes.SubscriptionCost) {
		if (tariffType === tariffTypes.Flex) return true;
		return false;
	}

	return true;
};

export const DienstStatus = {
	Open: 0,
	Assigned: 1,
	ProposedToFamily: 2,
	Confirmed: 3,
	Cancelled: 4,
	NanniesAsked: 5,
	CancelledByNanny: 6,
	AddedByNanny: 7,
	LookingForReplacement: 8,
	Replaced: 9,
	Last: 10
};

export const isCancelled = (status) => {
	return status === DienstStatus.Cancelled || status === DienstStatus.CancelledByNanny;
};

export const getDienstStatusLabel = (status) => {
	switch (status) {
		case DienstStatus.Open:
			return "Openstaand";
		case DienstStatus.NanniesAsked:
			return "Gevraagd";
		case DienstStatus.Cancelled:
			return "Geannuleerd";
		case DienstStatus.Assigned:
			return "Toegewezen aan nanny";
		case DienstStatus.ProposedToFamily:
			return "Voorgesteld bij gezin";
		case DienstStatus.Confirmed:
			return "Ingevuld";
		case DienstStatus.CancelledByNanny:
			return "Geannuleerd door nanny";
		case DienstStatus.AddedByNanny:
			return "Ingevuld door nanny";
		case DienstStatus.LookingForReplacement:
			return "Vervanging gezocht";
		case DienstStatus.Replaced:
			return "Vervangen";
		default:
			return "";
	}
};

export const getDienstStatusColor = (status) => {
	switch (status) {
		case DienstStatus.Open:
			return "#e9bfb1";
		case DienstStatus.NanniesAsked:
			return "#d0c4f0";
		case DienstStatus.Assigned:
			return "#6b5b93";
		case DienstStatus.ProposedToFamily:
			return "#e7d9aa";
		case DienstStatus.Confirmed:
			return "#c1e0c0";
		case DienstStatus.AddedByNanny:
			return "#8FC78D";
		case DienstStatus.Cancelled:
			return "#C91D25";
		case DienstStatus.CancelledByNanny:
			return "#8C1419";
		case DienstStatus.Replaced:
			return "#F0A500";
		case DienstStatus.LookingForReplacement:
			return "#ffcc85";
		default:
			return "#eeeeee";
	}
};

export const getDienstStatusLabelComponent = (status) => {
	let label = getDienstStatusLabel(status);
	if (!label) return null;

	return (
		<Label
			style={{
				color: getDienstStatusFontColor(status),
				backgroundColor: getDienstStatusColor(status)
			}}
		>
			{getDienstStatusLabel(status)}
		</Label>
	);
};

export const dienstStatusOptions = [
	DienstStatus.Open,
	DienstStatus.NanniesAsked,
	DienstStatus.Assigned,
	DienstStatus.ProposedToFamily,
	DienstStatus.Confirmed,
	DienstStatus.Cancelled,
	DienstStatus.CancelledByNanny,
	DienstStatus.AddedByNanny,
	DienstStatus.LookingForReplacement,
	DienstStatus.Replaced
].map((status) => ({
	key: status,
	value: status,
	text: getDienstStatusLabel(status),
	label: {
		style: { backgroundColor: getDienstStatusColor(status) },
		empty: true,
		circular: true
	}
}));

export const getDienstStatusFontColor = (status) => {
	if (status === DienstStatus.Cancelled) return "rgba(255,255,255,0.8)";
	if (status === DienstStatus.CancelledByNanny) return "rgba(255,255,255,0.8)";
	if (status === DienstStatus.Assigned) return "rgba(255,255,255,0.8)";
	return "rgba(0,0,0,0.5)";
};

export const DienstCancelReason = {
	Family: 0,
	Other: 1,
	Last: 2
};

export const getDienstCancelReasonLabel = (reason) => {
	switch (reason) {
		case DienstCancelReason.Family:
			return "Klant";
		case DienstCancelReason.Other:
			return "Anders";
		default:
			return "";
	}
};

export const dienstCancelReasonOptions = Array(DienstCancelReason.Last)
	.fill(0)
	.map((_, idx) => ({
		text: getDienstCancelReasonLabel(idx),
		value: idx,
		key: idx
	}));

export const DienstNannyStatus = {
	None: 0,
	Available: 1,
	Unavailable: 2,
	Last: 3
};

export const SORTEDSTATUS = [
	DienstNannyStatus.Unavailable,
	DienstNannyStatus.None,
	DienstNannyStatus.Available
];

export const getDienstNannyStatus = (status) => {
	if (status === DienstNannyStatus.None) return "Weet nog niet";
	if (status === DienstNannyStatus.Available) return "Ik kan!";
	if (status === DienstNannyStatus.Unavailable) return "Ik kan niet";
};

export const getDienstNannyStatusLabel = (status) => {
	if (status === DienstNannyStatus.None) return "Nog niet bekend";
	if (status === DienstNannyStatus.Available) return "Beschikbaar";
	if (status === DienstNannyStatus.Unavailable) return "Niet beschikbaar";
};

export const getNannyStatusColor = (status) => {
	if (status === DienstNannyStatus.None) return "#eeeeee";
	if (status === DienstNannyStatus.Available) return "#73B234";
	if (status === DienstNannyStatus.Unavailable) return "#C91D25";
};

export const getNannyStatusFontColor = (status) => {
	if (status === DienstNannyStatus.None) return "black";
	if (status === DienstNannyStatus.Available) return "white";
	if (status === DienstNannyStatus.Unavailable) return "white";
};

export const capitalizeFirstLetter = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

export const DienstType = {
	Extra: 0,
	Replacement: 1,
	LastMinute: 2,
	Last: 3
};

export const getDienstTypeLabel = (type) => {
	switch (type) {
		case DienstType.Extra:
			return "Extra dienst";
		case DienstType.Replacement:
			return "Vervanging voor vaste dienst";
		case DienstType.LastMinute:
			return "Last-minute";
	}

	return "";
};

export const dienstTypeOptions = Array(DienstType.Last)
	.fill(0)
	.map((_, idx) => ({
		key: idx,
		value: idx,
		text: getDienstTypeLabel(idx)
	}));

export const DienstSource = {
	Subscription: 0,
	Dienst: 1,
	Last: 2
};

export const getDienstSourceLabel = (dienst) => {
	if (dienst.source === DienstSource.Subscription) return "Abonnement";
	return getDienstTypeLabel(dienst.type);
};

export const getDienstNannyId = (dienst) => {
	if (!dienst) return null;
	if (dienst.assignedNannyId) return dienst.assignedNannyId;
	return dienst.nannyId;
};

export const getDienstNannyName = (dienst, nanniesById) => {
	if (!dienst) return "";

	let nannyId = getDienstNannyId(dienst);
	if (!nanniesById) return nannyId;

	if (nannyId) {
		let name = nanniesById[nannyId]?.name;
		if (!name) name = "Nanny niet gevonden";
		return `${name} (${nannyId})`;
	}

	return "Nog geen nanny";
};

export const SubscriptionStatus = {
	Open: 0,
	ProposedToFamily: 1,
	CheckSchedule: 2,
	CheckMatch: 3,
	Confirmed: 4,
	Cancelled: 5,
	Last: 6
};

export const getSubscriptionStatusLabel = (status) => {
	switch (status) {
		case SubscriptionStatus.Open:
			return "Open";
		case SubscriptionStatus.ProposedToFamily:
			return "Voorgesteld bij gezin";
		case SubscriptionStatus.CheckSchedule:
			return "Rooster afwachten";
		case SubscriptionStatus.CheckMatch:
			return "Match checken";
		case SubscriptionStatus.Confirmed:
			return "Ingevuld";
		default:
			return "";
	}
};

export const getSubscriptionStatusColor = (status) => {
	switch (status) {
		case SubscriptionStatus.Open:
			return "#e9bfb1";
		case SubscriptionStatus.ProposedToFamily:
			return "#ffcc85";
		case SubscriptionStatus.CheckSchedule:
			return "#d0c4f0";
		case SubscriptionStatus.CheckMatch:
			return "#6b5b93";
		case SubscriptionStatus.Confirmed:
			return "#c1e0c0";
		default:
			return "#eeeeee";
	}
};

export const subscriptionStatusOptions = [
	SubscriptionStatus.Open,
	SubscriptionStatus.ProposedToFamily,
	SubscriptionStatus.CheckSchedule,
	SubscriptionStatus.CheckMatch,
	SubscriptionStatus.Confirmed
].map((status) => ({
	key: status,
	value: status,
	text: getSubscriptionStatusLabel(status),
	label: {
		style: { backgroundColor: getSubscriptionStatusColor(status) },
		empty: true,
		circular: true
	}
}));

export const getSubscriptionStatusFontColor = (status) => {
	return "rgba(0,0,0,0.5)";
};

export const getSubscriptionStatusLabelComponent = (status) => {
	let label = getSubscriptionStatusLabel(status);
	if (!label) return null;

	return (
		<Label
			style={{
				color: getSubscriptionStatusFontColor(status),
				backgroundColor: getSubscriptionStatusColor(status)
			}}
		>
			{label}
		</Label>
	);
};

export const DATEFORMATFRIENDLY = "DD-MM-YYYY";
export const DATEFORMAT = "YYYY-MM-DD";

export const getFriendlyDateFromDateStr = (dateStr) =>
	moment(dateStr, DATEFORMAT).format(DATEFORMATFRIENDLY);

export const REGIONOPTIONS = {
	OOST: "Oost",
	WEST: "West"
};
