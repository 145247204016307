import React from "react";
import { Button, Modal, Form } from "semantic-ui-react";
import {
	CreditStatus,
	getCreditStatusOptions,
	CREDITNRMONTHSVALID,
	DATEFORMAT,
	DATEFORMATFRIENDLY
} from "../../constants";
import moment from "moment";
import { DatePicker } from "../../components/DatePicker";

const minDate = moment().add(-3, "month");
const maxDate = moment().endOf("month");

const getValidUntilDate = (originalDate) => originalDate.clone().add(CREDITNRMONTHSVALID, "month");

const CreditModal = ({
	open,
	onSubmit,
	onCancel,
	familyId,
	familyName,
	credit,
	selectedCreditIdx
}) => {
	const [originalDate, setOriginalDate] = React.useState(moment());
	const [status, setStatus] = React.useState(CreditStatus.Available);
	const [usedOn, setUsedOn] = React.useState(moment());
	const [validUntilDate, setValidUntilDate] = React.useState(getValidUntilDate(originalDate));

	React.useEffect(() => {
		setOriginalDate(credit ? moment(credit.dateStr, DATEFORMAT) : moment());
		setStatus(credit ? credit.status : CreditStatus.Available);
		setUsedOn(credit && credit.usedOn ? moment(credit.usedOn, DATEFORMAT) : moment());
		setValidUntilDate(
			credit ? moment(credit.validUntilDateStr, DATEFORMAT) : getValidUntilDate(moment())
		);
	}, [credit]);

	const handleOkClick = () => {
		const data = {
			dateStr: originalDate.format(DATEFORMAT),
			status,
			usedOn: status === CreditStatus.Used ? usedOn.format(DATEFORMAT) : null,
			validUntilDateStr: validUntilDate.format(DATEFORMAT)
		};

		onSubmit(selectedCreditIdx, data);
	};

	return (
		<Modal onClose={onCancel} open={open} size="small">
			<Modal.Header>
				Tegoed {credit ? "bewerken" : "toevoegen"} voor {familyId} - {familyName}
			</Modal.Header>
			<Modal.Content>
				<Form>
					<DatePicker
						disabled={!!credit}
						label="Originele datum"
						value={originalDate}
						onChange={setOriginalDate}
						min={minDate}
						max={maxDate}
					/>

					<Form.Dropdown
						label="Status"
						selection
						options={getCreditStatusOptions()}
						value={status}
						onChange={(evt, { value }) => setStatus(value)}
					/>

					<DatePicker
						label="Geldig tot"
						value={validUntilDate}
						onChange={(value) => setValidUntilDate(moment(value, DATEFORMATFRIENDLY))}
					/>

					{status === CreditStatus.Used && (
						<DatePicker
							label="Gebruikt op"
							value={usedOn}
							onChange={(value) => setUsedOn(moment(value, DATEFORMATFRIENDLY))}
						/>
					)}
				</Form>
			</Modal.Content>
			<Modal.Actions>
				<Button onClick={onCancel}>Cancel</Button>
				<Button onClick={handleOkClick}>Ok</Button>
			</Modal.Actions>
		</Modal>
	);
};

export default CreditModal;
