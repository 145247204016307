import React, { useState } from "react";
import styles from "./index.module.css";
import { DienstenTable } from "../../components/DienstenTable";
import { useReactionsListener } from "../../hooks/useReactions";
import { Button, Dimmer, Form, Icon, Loader } from "semantic-ui-react";
import { useModal } from "../../hooks/useModal";
import { AddEditModal } from "./AddEditModal";
import { useFamiliesById } from "../../hooks/useFamilies";
import { useKnownNannies } from "../../hooks/useKnownNannies";
import MonthDropdown from "../../components/MonthDropdown";
import moment from "moment";
import { CancelDienstButton } from "../../components/CancelDienstButton";
import { DeleteDienstModal } from "../../components/DeleteDienstModal";
import { useActualDiensten } from "../../hooks/useActualDiensten";
import { columns } from "../../components/DienstenTable/columns";

export const DienstenTab = () => {
	const [fromMonth, setFromMonth] = useState(moment().date(1).format("YYYY_MM"));
	const [toMonth, setToMonth] = useState(moment().date(1).format("YYYY_MM"));
	const [onlyFuture, setOnlyFuture] = useState(true);

	const { actualDiensten, loading: loadingDiensten } = useActualDiensten({
		familyId: null,
		fromMonthStr: fromMonth,
		toMonthStr: toMonth,
		includeReplaced: false,
		includeDiensten: true,
		includeSubscriptionDiensten: false,
		onlyLocalChanges: false,
		includeCancelled: true,
		onlyFutureDiensten: onlyFuture
	});
	const { reactions, loading: loadingReactions } = useReactionsListener();

	const familiesById = useFamiliesById();
	const knownNanniesPerFamily = useKnownNannies();

	const { modalProps: addEditModalProps, openModal: openAddEditModal } = useModal(actualDiensten);
	const { modalProps: deleteModalProps, openModal: openDeleteModal } = useModal(actualDiensten);

	const loading = loadingDiensten || loadingReactions || !familiesById || !knownNanniesPerFamily;

	return (
		<div className={styles.container}>
			{loading && (
				<Dimmer active>
					<Loader />
				</Dimmer>
			)}
			<AddEditModal
				{...addEditModalProps}
				knownNanniesPerFamily={knownNanniesPerFamily}
				familiesById={familiesById}
				reactions={reactions}
			/>
			<DeleteDienstModal
				{...deleteModalProps}
				collection="diensten"
				columns={columns}
				knownNanniesPerFamily={knownNanniesPerFamily}
				familiesById={familiesById}
				reactions={reactions}
			/>
			<div className={styles.headerContainer}>
				<div className={styles.selectMonthContainer}>
					<Form>
						<Form.Group inline style={{ marginBottom: 0 }}>
							<MonthDropdown
								label="Van"
								fluid={false}
								month={fromMonth}
								setMonth={setFromMonth}
								monthsAhead={12}
							/>
							<MonthDropdown
								label="Tot"
								fluid={false}
								month={toMonth}
								setMonth={setToMonth}
								monthsAhead={12}
							/>
							<Form.Checkbox
								label="Alleen toekomstige diensten"
								checked={onlyFuture}
								onChange={() => setOnlyFuture(!onlyFuture)}
							/>
						</Form.Group>
					</Form>
				</div>
				<div style={{ display: "flex" }}>
					<CancelDienstButton />
					<Button
						icon
						loading={loading}
						labelPosition="right"
						onClick={() => openAddEditModal(null)}
					>
						Nieuwe dienst
						<Icon name="add" />
					</Button>
				</div>
			</div>
			<DienstenTable
				diensten={actualDiensten}
				reactions={reactions}
				openAddEditModal={openAddEditModal}
				openDeleteModal={openDeleteModal}
			/>
		</div>
	);
};
