import React from "react";

import { Table } from "semantic-ui-react";
import NewInvoiceModal from "./NewInvoiceModal";

const NewInvoiceTable = ({ invoiceData, familiesByID, totalAmount }) => {
	const [modalFamilyId, setModalFamilyId] = React.useState(null);

	const onRowClick = (familyId) => {
		setModalFamilyId(familyId);
	};

	const onModalClose = () => {
		setModalFamilyId(null);
	};

	const getFamilyName = (familyId) => {
		let name = "Naam onbekend";
		if (familiesByID && familiesByID[familyId]) {
			name = familiesByID[familyId].name;
		}

		return name;
	};

	const sortedInvoiceFamilyIds = React.useMemo(() => {
		if (!invoiceData) return [];

		let keys = Object.keys(invoiceData);
		keys.sort((familyA, familyB) => {
			if (invoiceData[familyA].error && !invoiceData[familyB].error) return -1;
			if (invoiceData[familyB].error && !invoiceData[familyA].error) return 1;
			return familyA - familyB;
		});

		return keys;
	}, [invoiceData]);

	return (
		<>
			<h3>Per factuur</h3>
			<Table selectable>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell>Klantnummer</Table.HeaderCell>
						<Table.HeaderCell>Naam</Table.HeaderCell>
						<Table.HeaderCell>Factuurbedrag</Table.HeaderCell>
					</Table.Row>
				</Table.Header>

				<Table.Body>
					{sortedInvoiceFamilyIds &&
						sortedInvoiceFamilyIds.map((familyId) => {
							const { error, totalAmount } = invoiceData[familyId];
							let content = error || `€ ${totalAmount.toFixed(2)}`;

							return (
								<Table.Row key={familyId} onClick={() => onRowClick(familyId)}>
									<Table.Cell>{familyId}</Table.Cell>
									<Table.Cell>{getFamilyName(familyId)}</Table.Cell>
									<Table.Cell error={!!error}>{content}</Table.Cell>
								</Table.Row>
							);
						})}
				</Table.Body>

				<Table.Footer>
					<Table.Row>
						<Table.HeaderCell colSpan="2">
							<b>Totaal:</b>
						</Table.HeaderCell>
						<Table.HeaderCell>
							<b>€ {totalAmount.toFixed(2)}</b>
						</Table.HeaderCell>
					</Table.Row>
				</Table.Footer>
			</Table>
			<NewInvoiceModal
				open={!!modalFamilyId}
				onClose={onModalClose}
				familyId={modalFamilyId}
				familyName={modalFamilyId && getFamilyName(modalFamilyId)}
				invoice={modalFamilyId && invoiceData[modalFamilyId]}
			/>
		</>
	);
};

export default NewInvoiceTable;
