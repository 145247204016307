import React from "react";

import { Icon, Input, Segment, Menu, Button, Table } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import FileImportForm from "../../components/FileImportForm";

import { importSubscriptionsFile } from "./importSubscriptions";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

import styles from "./InvoiceTab.module.css";
import { useSubscriptions } from "../../hooks/useSubscriptions";
import { useNannySubscriptions } from "../../hooks/useNannySubscriptions";
import { useVastePlanning } from "../../hooks/useVastePlanning";

const InvoiceTab = ({}) => {
	const history = useHistory();

	const subscriptions = useSubscriptions();
	const nannySubscriptions = useNannySubscriptions();
	const vastePlanning = useVastePlanning();

	const [importState, setImportState] = React.useState("idle");
	const [importError, setImportError] = React.useState("");

	const handleImport = (file) => {
		setImportState("loading");
		importSubscriptionsFile(file, subscriptions, nannySubscriptions, false, vastePlanning)
			.then(() => {
				setImportState("success");
			})
			.catch((err) => {
				console.log(err);
				setImportState("error");
				setImportError(err);
			});
	};

	const loading = !subscriptions || !nannySubscriptions || !vastePlanning;

	return (
		<>
			<div className={styles.container}>
				<div className={styles.item}>
					<FileImportForm
						name="Families met abonnenementen/tarieven"
						loading={importState === "loading" || loading}
						error={importState === "error"}
						errorMessage={importError}
						success={importState === "success"}
						onSubmit={handleImport}
					/>
					<Button icon labelPosition="right" onClick={() => history.push("/invoice")}>
						Nieuwe facturen aanmaken
						<Icon name="add" />
					</Button>
				</div>
			</div>
		</>
	);
};

export default InvoiceTab;
