import moment from "moment";
import React, { useMemo, useState } from "react";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

import { Button, Modal, Form, Message } from "semantic-ui-react";
import { TimeInput } from "semantic-ui-calendar-react";

import { DatePicker } from "../../../components/DatePicker";
import { useFamiliesById } from "../../../hooks/useFamilies";
import { getDefaultPushContentDienst } from "../utils";
import {
	DATEFORMAT,
	DienstCancelReason,
	DienstNannyStatus,
	DienstSource,
	DienstStatus,
	DienstType,
	dienstCancelReasonOptions,
	dienstStatusOptions,
	dienstTypeOptions,
	isCancelled
} from "../../../constants";
import { FormGroup } from "../../../components/FormGroup";
import { AssignNannyGroup } from "../../../components/AssignNannyGroup";
import { DienstDropdown } from "../../../components/DienstDropdown";
import { ShowOtherDienstenButton } from "./ShowOtherDienstenButton";
import { FamilyDropdown } from "../../../components/FamilyDropdown";
import { SendPushFormGroup } from "../../../components/SendPushFormGroup";
import { useUnreadDienstenPerNanny } from "../../../hooks/useUnreadDienstenPerNanny";
import { ReactionsButton } from "./ReactionsButton";

export const AddEditModal = ({
	open,
	onClose,
	item,
	selectedItemId,
	knownNanniesPerFamily,
	reactions,
	loading,
	setLoading
}) => {
	const familiesByID = useFamiliesById();
	const unreadDienstenPerNanny = useUnreadDienstenPerNanny();

	// Loading state
	const [error, setError] = useState(null);

	// Algemeen
	const [status, setStatus] = useState(DienstStatus.Open);
	const [type, setType] = useState(DienstType.Extra);
	const [requestDate, setRequestDate] = useState(moment());

	const [cancelReason, setCancelReason] = useState(DienstCancelReason.Family);
	const [cancelledWithin72h, setCancelledWithin72h] = useState(false);

	// Waar en wanneer
	const [family, setFamily] = useState("");
	const [date, setDate] = useState(moment());
	const [startTime, setStartTime] = useState("00:00");
	const [endTime, setEndTime] = useState("00:00");

	const [replacedDienstFromMonth, replacedDienstToMonth] = useMemo(() => {
		let date = moment();
		if (item) {
			date = moment(item.date, DATEFORMAT);
		}

		let fromMonth = date.format("YYYY_MM");
		let toMonth = date.clone().add(2, "month").format("YYYY_MM");
		return [fromMonth, toMonth];
	}, [item]);

	// Opmerkingen
	const [internalRemarks, setInternalRemarks] = useState("");
	const [remarks, setRemarks] = useState("");

	// Nannies
	const [targetAllKnownNannies, setTargetAllKnownNannies] = useState(true);
	const [targetNannies, setTargetNannies] = useState([]);
	const [assignedNanny, setAssignedNanny] = useState("");

	// Pushbericht
	const [pushData, setPushData] = useState({
		send: false,
		title: "",
		content: ""
	});

	const [otherNanniesPushData, setOtherNanniesPushData] = useState({
		send: false,
		title: "",
		content: ""
	});

	const [replacedDienst, setReplacedDienst] = useState(null);
	const handleDienstChange = (e, { value }) => {
		if (!value) {
			setReplacedDienst(null);
			setDate(moment());
			setStartTime("00:00");
			setEndTime("00:00");
		} else {
			setReplacedDienst(value);
			setDate(moment(value.date, DATEFORMAT));

			if (value.startTime) setStartTime(value.startTime);
			if (value.endTime) setEndTime(value.endTime);
			if (value.remarks) setRemarks(value.remarks);
			if (value.internalRemarks) setInternalRemarks(value.internalRemarks);
		}
	};

	const reactionsByNanny = useMemo(() => {
		if (!reactions) return {};
		if (!reactions?.[selectedItemId]) return {};

		return reactions[selectedItemId].reduce(
			(obj, item) => ((obj[item.nannyId] = item), obj),
			{}
		);
	}, [reactions, selectedItemId]);

	const getDienstFormData = () => {
		const data = {
			date: date.format(DATEFORMAT),
			familyId: family,
			startTime,
			endTime,
			status,
			internalRemarks,
			remarks,
			targetAllKnownNannies,
			type
		};

		let isOpenDienst = status === DienstStatus.Open || status === DienstStatus.NanniesAsked;
		data.assignedNannyId = isOpenDienst ? "" : assignedNanny;

		if (!data.targetAllKnownNannies) {
			data.targetNannies = targetNannies;
		}

		if (isCancelled(status)) {
			data.cancelReason = cancelReason;
			data.cancelledWithin72h = cancelledWithin72h;
		}

		if (type === DienstType.Replacement && replacedDienst) {
			const { nannyId: replacedNannyId, date, familyId, startTime, endTime } = replacedDienst;

			let replacedData = {
				date,
				familyId,
				startTime,
				endTime
			};

			if (replacedNannyId) {
				replacedData.nannyId = replacedNannyId;
			}

			data.replacedDienst = replacedData;
		}

		return data;
	};

	const dienstFormData = useMemo(getDienstFormData, [
		date,
		family,
		startTime,
		endTime,
		status,
		internalRemarks,
		remarks,
		targetAllKnownNannies,
		type,
		assignedNanny,
		targetNannies,
		cancelReason,
		cancelledWithin72h,
		replacedDienst
	]);

	React.useEffect(() => {
		setOtherNanniesPushData({
			send: false,
			title: "",
			content: ""
		});

		if (open && item) {
			setDate(moment(item.date, DATEFORMAT));
			setFamily(item.familyId);
			setStartTime(item.startTime);
			setEndTime(item.endTime);
			setPushData({
				send: false,
				title: "Dienst aangepast",
				content: ""
			});

			setTargetAllKnownNannies(!!item.targetAllKnownNannies);
			setTargetNannies(item.targetNannies || []);
			setAssignedNanny(item.assignedNannyId || "");

			setInternalRemarks(item.internalRemarks || "");
			setRemarks(item.remarks || "");
			setStatus(item.status);
			setType(item.type);
			setCancelReason(item.cancelReason || DienstCancelReason.Family);
			setReplacedDienst(item.replacedDienst || null);
		} else {
			setDate(moment());
			setFamily(familiesByID ? Object.keys(familiesByID)[0] : "");
			setStartTime("00:00");
			setEndTime("00:00");
			setPushData({
				send: true,
				title: "Dienst beschikbaar",
				content: ""
			});

			setTargetNannies([]);
			setTargetAllKnownNannies(true);
			setAssignedNanny("");

			setInternalRemarks("");
			setRemarks("");
			setStatus(DienstStatus.Open);
			setType(DienstType.Extra);
			setCancelReason(DienstCancelReason.Family);
			setReplacedDienst(null);
		}
	}, [familiesByID, item, open]);

	// Update push notification content if necessary
	React.useEffect(() => {
		const { title, body } = getDefaultPushContentDienst(item, dienstFormData, familiesByID);

		if (title && body) {
			setPushData({
				send: true,
				title: title,
				content: body
			});
		} else {
			setPushData({
				send: false,
				title: "",
				content: ""
			});
		}

		// For other nannies as well
		if (
			item &&
			dienstFormData?.assignedNannyId &&
			dienstFormData.assignedNannyId !== item.assignedNannyId
		) {
			setOtherNanniesPushData({
				send: true,
				title: "Helaas! Dienst aan iemand anders toegewezen.",
				content: body
			});
		} else {
			setOtherNanniesPushData({
				send: false,
				title: "",
				content: ""
			});
		}
	}, [familiesByID, dienstFormData, item]);

	const handleTimeChange = (time, isFrom) => {
		if (isFrom) {
			setStartTime(time);
		} else {
			setEndTime(time);
		}
	};

	const handleConfirm = async () => {
		setLoading(true);
		setError(null);

		const data = getDienstFormData();

		data.updateTimestamp = firebase.firestore.FieldValue.serverTimestamp();
		if (!selectedItemId) {
			data.creationTimestamp = firebase.firestore.FieldValue.serverTimestamp();
		}

		if (pushData.send) {
			data.pushTimestamp = firebase.firestore.FieldValue.serverTimestamp();
		}

		let fbRef = selectedItemId
			? firebase.firestore().collection("diensten").doc(selectedItemId)
			: firebase.firestore().collection("diensten").doc();

		console.log("data", data);

		try {
			await fbRef.set(data, { merge: true });
		} catch (error) {
			console.error("Error adding dienst document: ", error, data);
			setError("Er is een fout opgetreden bij het opslaan van de dienst.");
			return;
		}

		if (pushData.send) {
			let nanniesToSendTo;
			if (data.assignedNannyId) {
				nanniesToSendTo = [data.assignedNannyId];
			} else {
				nanniesToSendTo = data.targetAllKnownNannies
					? knownNanniesPerFamily[family].map((nanny) => nanny.id)
					: data.targetNannies;
			}

			if (nanniesToSendTo?.length > 0) {
				let badgeCountPerNanny = {};
				for (let nannyId of nanniesToSendTo) {
					badgeCountPerNanny[nannyId] = unreadDienstenPerNanny[nannyId] || 0;
					// Plus 1 for new push
					badgeCountPerNanny[nannyId]++;
				}

				let fbPushData = {
					title: pushData.title,
					content: pushData.content,
					type: "dienst",
					dienstId: fbRef.id,
					dienstDate: data.date,
					badgeCountPerNanny,
					timestamp: firebase.firestore.FieldValue.serverTimestamp()
				};

				try {
					await firebase.firestore().collection("push").add(fbPushData);
				} catch (error) {
					console.error("Error adding push document: ", error, fbPushData);
				}
			}
		}

		if (data.assignedNannyId && otherNanniesPushData.send) {
			let nanniesToSendTo = data.targetAllKnownNannies
				? knownNanniesPerFamily[family].map((nanny) => nanny.id)
				: data.targetNannies;

			// Not to assigned nanny
			nanniesToSendTo = nanniesToSendTo.filter((nannyId) => nannyId !== data.assignedNannyId);

			// Only to 'Available' nannies
			nanniesToSendTo = nanniesToSendTo.filter(
				(nannyId) => reactionsByNanny[nannyId]?.status === DienstNannyStatus.Available
			);

			if (nanniesToSendTo?.length > 0) {
				let badgeCountPerNanny = {};
				for (let nannyId of nanniesToSendTo) {
					badgeCountPerNanny[nannyId] = unreadDienstenPerNanny[nannyId] || 0;
					// Plus 1 for new push
					badgeCountPerNanny[nannyId]++;
				}

				let fbPushData = {
					title: otherNanniesPushData.title,
					content: otherNanniesPushData.content,
					type: "dienst",
					badgeCountPerNanny,
					timestamp: firebase.firestore.FieldValue.serverTimestamp()
				};

				try {
					await firebase.firestore().collection("push").add(fbPushData);
				} catch (error) {
					console.error("Error adding push document: ", error, fbPushData);
				}
			}
		}

		setLoading(false);
		onClose();
	};

	const isValid = () => {
		if (!family) return false;
		return true;
	};

	const knownNannyOptions = useMemo(() => {
		if (!family) return [];
		if (!knownNanniesPerFamily) return [];

		const nannies = knownNanniesPerFamily[family] || [];
		return nannies.map((nanny) => ({
			text: `${nanny.name} (${nanny.id})`,
			value: nanny.id,
			key: nanny.id
		}));
	}, [family, knownNanniesPerFamily]);

	const onFamilyChange = (e, { value }) => {
		setFamily(value);

		const nannies = knownNanniesPerFamily?.[value];
		setTargetNannies(nannies?.map((nanny) => nanny.id) || []);
	};

	const handleStatusChange = (e, { value }) => {
		// Update push group
		let sendPush = true;
		if (value === DienstStatus.ProposedToFamily) sendPush = false;
		setPushData((old) => ({ ...old, send: sendPush }));

		// Pre-set cancelled within 72h based on date
		if (value === DienstStatus.Cancelled) {
			const dateWithTime = moment(date).set({
				hour: parseInt(startTime.split(":")[0]),
				minute: parseInt(startTime.split(":")[1])
			});

			const diff = dateWithTime.diff(moment(), "hours");
			setCancelledWithin72h(diff < 72);
		}

		setStatus(value);
	};

	const isOpenDienst = status === DienstStatus.Open || status === DienstStatus.NanniesAsked;
	const isNewDienst = !item;
	const disabled = item && isCancelled(item.status);
	let header;
	if (isNewDienst) {
		header = "Nieuwe dienst toevoegen";
	} else if (disabled) {
		header = "Geannuleerde dienst bekijken";
	} else {
		header = "Dienst aanpassen";
	}

	let showAssignNannyGroup = !isOpenDienst;
	if (status === DienstStatus.Cancelled && !assignedNanny) {
		showAssignNannyGroup = false;
	}

	return (
		<Modal size="small" open={open} onClose={onClose}>
			<Modal.Header>{header}</Modal.Header>
			<Modal.Content>
				<Form>
					<FormGroup label="Algemeen" collapsable>
						<Form.Dropdown
							label="Status"
							selection
							options={dienstStatusOptions}
							value={status}
							onChange={handleStatusChange}
						/>
						{isCancelled(status) && (
							<>
								<Form.Dropdown
									label="Reden annulering"
									selection
									options={dienstCancelReasonOptions}
									value={cancelReason}
									onChange={(e, { value }) => setCancelReason(value)}
									disabled={disabled}
								/>
								<Form.Checkbox
									label="Geannuleerd binnen 72u"
									selection
									checked={cancelledWithin72h}
									onChange={(e, { checked }) => setCancelledWithin72h(checked)}
									disabled={disabled}
								/>
							</>
						)}
						<Form.Dropdown
							label="Type aanvraag"
							selection
							options={dienstTypeOptions}
							value={type}
							onChange={(e, { value }) => setType(value)}
							disabled={disabled}
						/>
						<DatePicker
							label="Datum aanvraag"
							value={requestDate}
							onChange={setRequestDate}
							disabled={disabled}
						/>
					</FormGroup>
					<FormGroup label="Waar en wanneer" collapsable defaultCollapsed={!isNewDienst}>
						<FamilyDropdown
							value={family}
							onChange={onFamilyChange}
							disabled={disabled}
						/>
						{type === DienstType.Replacement && (
							<DienstDropdown
								label="Vaste dienst om te vervangen"
								familyId={family}
								value={replacedDienst?.id}
								onChange={handleDienstChange}
								source={DienstSource.Subscription}
								fromMonthStr={replacedDienstFromMonth}
								toMonthStr={replacedDienstToMonth}
								disabled={disabled}
								includeReplaced
								includeDiensten={false}
							/>
						)}
						<Form.Group widths="equal">
							{type === DienstType.Replacement || (
								<DatePicker
									label="Datum"
									value={date}
									onChange={setDate}
									disabled={disabled}
								/>
							)}
							<TimeInput
								label="Van"
								value={startTime}
								onChange={(e, { value }) => handleTimeChange(value, true)}
								closable
								disabled={disabled}
							/>
							<TimeInput
								label="Tot"
								value={endTime}
								onChange={(e, { value }) => handleTimeChange(value, false)}
								closable
								disabled={disabled}
							/>
						</Form.Group>
						<ShowOtherDienstenButton
							familyId={family}
							family={familiesByID?.[family]}
						/>
					</FormGroup>
					<FormGroup label="Opmerkingen / bijzonderheden" collapsable defaultCollapsed>
						<Form.TextArea
							label="NAAR NANNIES"
							value={remarks}
							onChange={(e, { value }) => setRemarks(value)}
							disabled={disabled}
						/>
						<Form.TextArea
							label="INTERN"
							value={internalRemarks}
							onChange={(e, { value }) => setInternalRemarks(value)}
							disabled={disabled}
						/>
					</FormGroup>
					<FormGroup
						label={showAssignNannyGroup ? "Nanny toewijzen" : "Nannies"}
						collapsable
						defaultCollapsed={!isNewDienst && item?.status !== DienstStatus.Open}
					>
						{showAssignNannyGroup ? (
							<AssignNannyGroup
								nanny={assignedNanny}
								setNanny={setAssignedNanny}
								reactionsByNanny={reactionsByNanny}
								knownNannies={knownNanniesPerFamily[family]}
								disabled={disabled}
							/>
						) : (
							<>
								<Form.Field
									label="Zichtbaar voor nannies"
									style={{ margin: 0 }}
									disabled={disabled}
								/>
								<Form.Group inline>
									<Form.Radio
										label={`Alle bekende nannies (${knownNannyOptions?.length})`}
										value={true}
										checked={targetAllKnownNannies}
										onChange={(e, { value }) => setTargetAllKnownNannies(value)}
										disabled={disabled}
									/>
									<Form.Radio
										label="Selecteer uit bekende nannies"
										value={false}
										checked={!targetAllKnownNannies}
										onChange={(e, { value }) => setTargetAllKnownNannies(value)}
										disabled={disabled}
									/>
								</Form.Group>
								{!targetAllKnownNannies && (
									<Form.Dropdown
										placeholder="Selecteer nannies"
										value={targetNannies}
										onChange={(e, { value }) => setTargetNannies(value)}
										fluid
										multiple
										search
										selection
										clearable
										options={knownNannyOptions}
										disabled={disabled}
									/>
								)}
							</>
						)}

						<ReactionsButton
							reactions={reactions?.[selectedItemId]}
							loading={loading}
						/>
					</FormGroup>

					<SendPushFormGroup
						label={
							showAssignNannyGroup
								? "Pushmelding versturen naar toegewezen Nanny"
								: "Pushmelding versturen"
						}
						disabled={disabled}
						pushData={pushData}
						setPushData={setPushData}
					/>

					{showAssignNannyGroup && (
						<SendPushFormGroup
							label="Pushmelding versturen naar beschikbare nannies die dienst NIET hebben"
							disabled={disabled}
							pushData={otherNanniesPushData}
							setPushData={setOtherNanniesPushData}
						/>
					)}
				</Form>
				{error && <Message error>{error}</Message>}
			</Modal.Content>
			<Modal.Actions>
				<Button onClick={onClose}>Terug</Button>
				{disabled || (
					<Button
						content="Ok"
						labelPosition="right"
						icon="checkmark"
						onClick={handleConfirm}
						primary
						disabled={!isValid()}
						loading={loading}
					/>
				)}
			</Modal.Actions>
		</Modal>
	);
};
