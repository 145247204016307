function onlyUnique(value, index, self) {
	return self.indexOf(value) === index;
}

export const getFamiliesForInvoices = (invoices, newCredits, flexSubscriptions, onlyFamilies) => {
	const allFamilies = [
		...Object.keys(invoices),
		...Object.keys(newCredits),
		...Object.keys(flexSubscriptions)
	]
		.filter(onlyUnique) // Remove duplicates
		.filter((familyId) => familyId.substring(0, 2) === "AN") // Remove invalids and TEST
		.filter((familyId) => {
			// Filter onlyFamilies
			if (onlyFamilies.length > 0) {
				return onlyFamilies.includes(familyId);
			}
			return true;
		});

	return allFamilies;
};
