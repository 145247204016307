import { Button, Form, Icon, Modal } from "semantic-ui-react";
import React, { useEffect, useMemo, useState } from "react";
import {
	capitalizeFirstLetter,
	DienstCancelReason,
	DienstSource,
	DienstStatus,
	getDienstCancelReasonLabel,
	getDienstNannyId
} from "../../constants";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { FamilyDropdown } from "../FamilyDropdown";
import MonthDropdown from "../MonthDropdown";
import moment from "moment";
import { DienstDropdown } from "../DienstDropdown";
import { SendPushFormGroup } from "../SendPushFormGroup";
import { useUnreadDienstenForNanny } from "../../hooks/useUnreadDienstenPerNanny";
import { useFamiliesById } from "../../hooks/useFamilies";

export const CancelDienstButton = ({}) => {
	const familiesById = useFamiliesById();

	const [open, setOpen] = useState(false);
	const [reason, setReason] = useState(DienstCancelReason.Family);
	const [loading, setLoading] = useState(false);
	const [family, setFamily] = useState(null);
	const [month, setMonth] = useState(moment().date(1).format("YYYY_MM"));
	const [dienst, setDienst] = useState(null);
	const [within72h, setWithin72h] = useState(false);
	const [pushData, setPushData] = useState({
		send: false,
		title: "Dienst geannuleerd",
		content: ""
	});

	const { unreadCount, loading: unreadLoading } = useUnreadDienstenForNanny(
		getDienstNannyId(dienst)
	);

	useEffect(() => {
		if (dienst) {
			const familyName = familiesById[family]?.name;
			const dateStr = capitalizeFirstLetter(
				moment(dienst.date, "YYYY-MM-DD").format("dddd D MMMM")
			);

			let body = `${familyName}\n${dateStr}\n${dienst.startTime} tot ${dienst.endTime}`;
			setPushData({
				send: true,
				title: "Dienst geannuleerd",
				content: body
			});
		}
	}, [dienst, familiesById, family]);

	const clearState = () => {
		setOpen(false);
		setFamily(null);
		setMonth(moment().date(1).format("YYYY_MM"));
		setDienst(null);
		setReason(DienstCancelReason.Family);
		setWithin72h(false);
		setPushData({
			send: false,
			title: "Dienst geannuleerd",
			content: ""
		});
	};

	const handleConfirm = async () => {
		if (!dienst) return;

		setLoading(true);

		if (dienst.source === DienstSource.Subscription) {
			let fbRef = firebase.firestore().collection("adjustments").doc(dienst.id);
			await fbRef.set(
				{
					familyId: family,
					date: dienst.date,
					nannyId: dienst.nannyId,
					startTime: dienst.startTime,
					endTime: dienst.endTime,
					adjustment: {
						status: DienstStatus.Cancelled,
						cancelledWithin72h: within72h,
						cancelReason: reason
					},
					adjustedBy: "admin",
					timestamp: firebase.firestore.FieldValue.serverTimestamp()
				},
				{ merge: true }
			);
		} else {
			let fbRef = firebase.firestore().collection("diensten").doc(dienst.id);
			await fbRef.set(
				{
					status: DienstStatus.Cancelled,
					cancelledBy: "admin",
					cancelReason: reason,
					cancelledWithin72h: within72h
				},
				{ merge: true }
			);
		}

		if (pushData.send) {
			let nannyId = getDienstNannyId(dienst);
			if (nannyId) {
				let fbPushData = {
					title: pushData.title,
					content: pushData.content,
					type: "dienst",
					dienstId: dienst.id,
					dienstDate: dienst.date,
					badgeCountPerNanny: { [nannyId]: unreadCount + 1 },
					timestamp: firebase.firestore.FieldValue.serverTimestamp()
				};

				try {
					firebase.firestore().collection("push").add(fbPushData);
				} catch (error) {
					console.error("Error adding push document: ", error, fbPushData);
				}
			}
		}

		setLoading(false);
		clearState();
	};

	return (
		<>
			<div style={{ display: "flex", justifyContent: "flex-end" }}>
				<Button
					icon
					labelPosition="right"
					onClick={() => setOpen(true)}
					compact
					loading={loading}
				>
					Annuleer dienst
					<Icon name="ban" />
				</Button>
			</div>
			<Modal open={open} onClose={() => setOpen(false)} onOpen={() => setOpen(true)}>
				<Modal.Header>Dienst annuleren</Modal.Header>
				<Modal.Content>
					<Form>
						<FamilyDropdown
							value={family}
							onChange={(e, { value }) => setFamily(value)}
							placeholder="Selecteer gezin"
						/>

						<MonthDropdown
							label="Maand"
							month={month}
							setMonth={setMonth}
							monthsAhead={12}
						/>

						<DienstDropdown
							familyId={family}
							value={dienst}
							onChange={(e, { value }) => setDienst(value)}
							fromMonthStr={month}
							toMonthStr={month}
						/>

						<Form.Dropdown
							label="Reden"
							selection
							options={Array(DienstCancelReason.Last)
								.fill(0)
								.map((_, idx) => ({
									text: getDienstCancelReasonLabel(idx),
									value: idx,
									key: idx
								}))}
							value={reason}
							onChange={(e, { value }) => setReason(value)}
						/>

						<Form.Checkbox
							label="Geannuleerd binnen 72u"
							selection
							checked={within72h}
							onChange={(e, { checked }) => setWithin72h(checked)}
						/>

						<SendPushFormGroup
							label={"Pushmelding versturen"}
							pushData={pushData}
							setPushData={setPushData}
						/>
					</Form>
				</Modal.Content>
				<Modal.Actions>
					<Button onClick={() => setOpen(false)}>Cancel</Button>
					<Button primary onClick={handleConfirm} loading={loading}>
						Annuleer dienst
					</Button>
				</Modal.Actions>
			</Modal>
		</>
	);
};
