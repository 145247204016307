import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

import { Button, Modal, Form, Message, Input } from "semantic-ui-react";
import { TimeInput } from "semantic-ui-calendar-react";

import { DatePicker } from "../../components/DatePicker";
import { useFamiliesById } from "../../hooks/useFamilies";
import { FormGroup } from "../../components/FormGroup";
import { AssignNannyGroup } from "../../components/AssignNannyGroup";
import { FamilyDropdown } from "../../components/FamilyDropdown";
import {
	SubscriptionStatus,
	subscriptionStatusOptions,
	DATEFORMAT,
	REGIONOPTIONS
} from "../../constants";

export const AddEditModal = ({
	open,
	onClose,
	item,
	selectedItemId,
	knownNanniesPerFamily,
	loading,
	setLoading
}) => {
	const familiesByID = useFamiliesById();

	// Loading state
	const [error, setError] = useState(null);

	// Algemeen
	const [status, setStatus] = useState(SubscriptionStatus.Open);
	const [region, setRegion] = useState(REGIONOPTIONS.OOST);
	const [dayWillStop, setDayWillStop] = useState(false);
	const [dayWillStopReason, setDayWillStopReason] = useState("");

	// Waar en wanneer
	const [family, setFamily] = useState("");
	const [location, setLocation] = useState("");
	const [kids, setKids] = useState("");
	const [startDate, setStartDate] = useState(moment());
	const [endDate, setEndDate] = useState(null);
	const [startTime, setStartTime] = useState("00:00");
	const [endTime, setEndTime] = useState("00:00");

	// Opmerkingen
	const [remarks, setRemarks] = useState("");

	// Nannies
	const [nanny, setNanny] = useState("");

	const getDienstFormData = () => {
		const data = {
			startDate: startDate.format(DATEFORMAT),
			endDate: endDate ? endDate.format(DATEFORMAT) : "",
			familyId: family,
			startTime,
			endTime,
			status,
			remarks,
			location,
			dayWillStop,
			dayWillStopReason,
			kids,
			region
		};

		if (familiesByID[family]) {
			data.familyName = familiesByID[family].name;
		}

		let isOpenDienst = status === SubscriptionStatus.Open;
		data.nannyId = isOpenDienst ? "" : nanny;

		return data;
	};

	useEffect(() => {
		if (open && item) {
			setStartDate(moment(item.startDate, DATEFORMAT));
			setEndDate(item.endDate ? moment(item.endDate, DATEFORMAT) : null);
			setFamily(item.familyId);
			setStartTime(item.startTime);
			setEndTime(item.endTime);
			setNanny(item.nannyId || "");
			setLocation(item.location || "");
			setKids(item.kids || "");

			setRemarks(item.remarks || "");
			setStatus(item.status);
			setDayWillStop(!!item.dayWillStop);
			setDayWillStopReason(item.dayWillStopReason || "");
			setRegion(item.region || REGIONOPTIONS.OOST);
		} else {
			setStartDate(moment());
			setEndDate(null);
			setFamily(familiesByID ? Object.keys(familiesByID)[0] : "");
			setStartTime("00:00");
			setEndTime("00:00");
			setNanny("");
			setLocation("");
			setKids("");

			setRemarks("");
			setStatus(SubscriptionStatus.Open);
			setDayWillStop(false);
			setDayWillStopReason("");
			setRegion(REGIONOPTIONS.OOST);
		}
	}, [familiesByID, item, open]);

	const handleTimeChange = (time, isFrom) => {
		if (isFrom) {
			setStartTime(time);
		} else {
			setEndTime(time);
		}
	};

	const handleConfirm = async () => {
		setLoading(true);
		setError(null);

		const data = getDienstFormData();

		data.updateTimestamp = firebase.firestore.FieldValue.serverTimestamp();
		if (!selectedItemId) {
			data.creationTimestamp = firebase.firestore.FieldValue.serverTimestamp();
		}

		let fbRef = selectedItemId
			? firebase.firestore().collection("vastePlanning").doc(selectedItemId)
			: firebase.firestore().collection("vastePlanning").doc();

		const { remarks, location, kids, dayWillStop, dayWillStopReason, region, ...rest } = data;

		let adminRef = firebase.firestore().collection("vastePlanningAdmin").doc(fbRef.id);
		const adminData = {
			startDate: rest.startDate,
			endDate: rest.endDate,
			remarks,
			location,
			kids,
			dayWillStop,
			dayWillStopReason,
			region
		};

		try {
			await adminRef.set(adminData, { merge: true });
		} catch (error) {
			console.error("Error adding vastePlanningAdmin document: ", error, adminData);
			setError("Er is een fout opgetreden bij het opslaan van de dienst.");
			return;
		}

		try {
			await fbRef.set(rest, { merge: true });
		} catch (error) {
			console.error("Error adding vastePlanning document: ", error, data);
			setError("Er is een fout opgetreden bij het opslaan van de dienst.");
			return;
		}

		setLoading(false);
		onClose();
	};

	const isValid = () => {
		if (!family) return false;
		return true;
	};

	const onFamilyChange = (e, { value }) => {
		setFamily(value);
	};

	const handleStatusChange = (e, { value }) => {
		setStatus(value);
	};

	const isOpenDienst = status === SubscriptionStatus.Open;
	const isNewDienst = !item;
	let header;
	if (isNewDienst) {
		header = "Nieuwe dag aan vaste planning toevoegen";
	} else {
		header = "Vaste dag aanpassen";
	}

	let showAssignNannyGroup = !isOpenDienst;
	let disabled = false;

	return (
		<Modal size="small" open={open} onClose={onClose}>
			<Modal.Header>{header}</Modal.Header>
			<Modal.Content>
				<Form>
					<FormGroup label="Algemeen" collapsable>
						<Form.Dropdown
							label="Status"
							selection
							options={subscriptionStatusOptions}
							value={status}
							onChange={handleStatusChange}
						/>
						<DatePicker
							label="Datum"
							value={startDate}
							onChange={setStartDate}
							disabled={disabled}
						/>
						<DatePicker
							label="Eind datum"
							value={endDate}
							onChange={setEndDate}
							disabled={disabled}
							clearable
						/>
						<Form.Checkbox
							label="Gezin stopt hierna met deze vaste dag"
							checked={dayWillStop}
							onChange={(e, { checked }) => setDayWillStop(checked)}
							disabled={disabled}
						/>
						<Form.Input
							label="Reden"
							value={dayWillStopReason}
							onChange={(e, { value }) => setDayWillStopReason(value)}
							disabled={disabled || !dayWillStop}
						/>
					</FormGroup>
					<FormGroup label="Gezin" collapsable>
						<Form.Dropdown
							label="Regio"
							selection
							options={Object.values(REGIONOPTIONS).map((r) => ({
								key: r,
								text: r,
								value: r
							}))}
							value={region}
							onChange={(e, { value }) => setRegion(value)}
							disabled={disabled}
						/>
						<FamilyDropdown
							value={family}
							onChange={onFamilyChange}
							disabled={disabled}
						/>
						<Form.Input
							label="Locatie"
							value={location}
							onChange={(e, { value }) => setLocation(value)}
							disabled={disabled}
						/>
						<Form.Input
							label="Kinderen"
							value={kids}
							onChange={(e, { value }) => setKids(value)}
							disabled={disabled}
						/>
					</FormGroup>
					<FormGroup label="Tijden" collapsable>
						<Form.Group widths="equal">
							<TimeInput
								label="Van"
								value={startTime}
								onChange={(e, { value }) => handleTimeChange(value, true)}
								closable
								disabled={disabled}
							/>
							<TimeInput
								label="Tot"
								value={endTime}
								onChange={(e, { value }) => handleTimeChange(value, false)}
								closable
								disabled={disabled}
							/>
						</Form.Group>
						<div
							style={{
								flex: 1,
								backgroundColor: "#eeeeee",
								padding: 8,
								borderRadius: 4,
								marginBottom: 8
							}}
						>
							<p>
								{"Elke " +
									moment(startDate).format("dddd") +
									" van " +
									startTime +
									" tot " +
									endTime}
							</p>
						</div>
					</FormGroup>
					{showAssignNannyGroup && (
						<FormGroup label={"Nanny"}>
							<AssignNannyGroup
								nanny={nanny}
								setNanny={setNanny}
								knownNannies={knownNanniesPerFamily?.[family]}
								disabled={disabled}
								clearable
							/>
						</FormGroup>
					)}
					<FormGroup label="Extra informatie" collapsable>
						<Form.TextArea
							value={remarks}
							onChange={(e, { value }) => setRemarks(value)}
							disabled={disabled}
						/>
					</FormGroup>
				</Form>
				{error && <Message error>{error}</Message>}
			</Modal.Content>
			<Modal.Actions>
				<Button onClick={onClose}>Terug</Button>
				{disabled || (
					<Button
						content="Ok"
						labelPosition="right"
						icon="checkmark"
						onClick={handleConfirm}
						primary
						disabled={!isValid()}
						loading={loading}
					/>
				)}
			</Modal.Actions>
		</Modal>
	);
};
