export const getInvoiceForFamily = (invoices, familyId, addifnotfound) => {
	if (addifnotfound) {
		if (!(familyId in invoices)) {
			invoices[familyId] = {
				customerId: familyId,
				lines: [],
				totalAmount: 0
			};
		}
	}

	return invoices[familyId];
};
