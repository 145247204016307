import React, { useState } from "react";
import styles from "./index.module.css";
import { Button, Dimmer, Form, Icon, Loader } from "semantic-ui-react";
import { AddEditModal } from "./AddEditModal";
import { useModal } from "../../hooks/useModal";
import { useVastePlanning } from "../../hooks/useVastePlanning";
import { DienstenTable } from "../../components/DienstenTable";
import { vastePlanningColumns } from "./columns";
import { useFamiliesById } from "../../hooks/useFamilies";
import { useKnownNannies } from "../../hooks/useKnownNannies";
import { DeleteDienstModal } from "../../components/DeleteDienstModal";
import moment from "moment";
import MonthDropdown from "../../components/MonthDropdown";

export const VastePlanningTab = () => {
	const [fromMonth, setFromMonth] = useState(moment().date(1).format("YYYY_MM"));
	const [toMonth, setToMonth] = useState(moment().date(1).format("YYYY_MM"));
	const [onlyFuture, setOnlyFuture] = useState(true);

	const vastePlanning = useVastePlanning({
		fromMonthStr: fromMonth,
		toMonthStr: toMonth,
		onlyFuture
	});
	const { modalProps, openModal } = useModal(vastePlanning);
	const { modalProps: deleteModalProps, openModal: openDeleteModal } = useModal(vastePlanning);

	const familiesById = useFamiliesById();
	const knownNanniesPerFamily = useKnownNannies();

	const loading = !vastePlanning;

	return (
		<div className={styles.container}>
			{loading && (
				<Dimmer active>
					<Loader />
				</Dimmer>
			)}
			<AddEditModal
				{...modalProps}
				knownNanniesPerFamily={knownNanniesPerFamily}
				familiesById={familiesById}
			/>
			<DeleteDienstModal
				{...deleteModalProps}
				collection="vastePlanning"
				columns={vastePlanningColumns}
				knownNanniesPerFamily={knownNanniesPerFamily}
				familiesById={familiesById}
			/>
			<div className={styles.headerContainer}>
				<div className={styles.selectMonthContainer}>
					<Form>
						<Form.Group inline style={{ marginBottom: 0 }}>
							<MonthDropdown
								label="Van"
								fluid={false}
								month={fromMonth}
								setMonth={setFromMonth}
								monthsAhead={12}
							/>
							<MonthDropdown
								label="Tot"
								fluid={false}
								month={toMonth}
								setMonth={setToMonth}
								monthsAhead={12}
							/>
							<Form.Checkbox
								label="Alleen toekomstige diensten"
								checked={onlyFuture}
								onChange={() => setOnlyFuture(!onlyFuture)}
							/>
						</Form.Group>
					</Form>
				</div>
				<div style={{ display: "flex", justifyContent: "flex-end", flex: 1 }}>
					<Button
						icon
						loading={loading}
						labelPosition="right"
						onClick={() => openModal(null)}
					>
						Nieuwe vaste dag
						<Icon name="add" />
					</Button>
				</div>
			</div>
			<DienstenTable
				diensten={vastePlanning}
				openAddEditModal={openModal}
				columns={vastePlanningColumns}
				openDeleteModal={openDeleteModal}
			/>
		</div>
	);
};
