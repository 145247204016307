const sortOnDateStr = (a, b) => {
	if (!a.dateStr && b.dateStr) return -1;
	if (!b.dateStr && a.dateStr) return 1;
	if (a.dateStr < b.dateStr) return -1;
	if (a.dateStr > b.dateStr) return 1;
	return 0;
};

export const finalizeInvoice = (invoice) => {
	if (!invoice.lines || invoice.lines.length === 0) {
		if (!invoice.error) invoice.error = "Geen regels voor factuur";
	}

	invoice.lines.sort(sortOnDateStr);
	invoice.totalAmount = invoice.lines.reduce((partialSum, line) => {
		if (line.amount) return partialSum + line.amount;
		return partialSum;
	}, 0);
};
