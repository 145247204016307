import React, { useEffect, useMemo } from "react";
import firebase from "firebase/app";
import "firebase/firestore";
import moment from "moment";
import { DATEFORMAT } from "../constants";

export const useVastePlanning = (options = {}) => {
	const { fromMonthStr, toMonthStr, onlyFuture } = options;
	const [vastePlanningRaw, setVastePlanningRaw] = React.useState(null);
	const [vastePlanningAdminRaw, setVastePlanningAdminRaw] = React.useState(null);

	useEffect(() => {
		const collectionRef = firebase.firestore().collection("vastePlanning");
		return collectionRef.onSnapshot((querySnapshot) => {
			console.log("vastePlanning snapshot came in");

			let data = {};
			querySnapshot.forEach((doc) => {
				data[doc.id] = doc.data();
			});
			setVastePlanningRaw(data);
		});
	}, []);

	useEffect(() => {
		const collectionRef = firebase.firestore().collection("vastePlanningAdmin");
		return collectionRef.onSnapshot((querySnapshot) => {
			console.log("vastePlanningAdmin snapshot came in");

			let data = {};
			querySnapshot.forEach((doc) => {
				data[doc.id] = doc.data();
			});
			setVastePlanningAdminRaw(data);
		});
	}, []);

	const data = useMemo(() => {
		if (!vastePlanningRaw) return [];
		if (!vastePlanningAdminRaw) return [];

		let fromMonth;
		if (fromMonthStr) {
			fromMonth = moment(fromMonthStr, "YYYY_MM").startOf("month").format(DATEFORMAT);
		}

		let toMonth;
		if (toMonthStr) {
			toMonth = moment(toMonthStr, "YYYY_MM").endOf("month").format(DATEFORMAT);
		}

		let data = [];
		for (let id in vastePlanningRaw) {
			const rec = vastePlanningRaw[id];
			if (!rec) continue;

			const { creationTimestamp, updateTimestamp, startDate, endDate, ...rest } = rec;
			if (toMonth && startDate > toMonth) continue;
			if (fromMonth && endDate && endDate < fromMonth) continue;
			if (onlyFuture && endDate && endDate < moment().format(DATEFORMAT)) continue;

			const adminRec = vastePlanningAdminRaw[id];
			data.push({
				id: id,
				creationTimestamp: creationTimestamp && creationTimestamp.toDate(),
				updateTimestamp: updateTimestamp && updateTimestamp.toDate(),
				startDate,
				endDate,
				...rest,
				...(adminRec || {})
			});
		}

		return data;
	}, [vastePlanningAdminRaw, vastePlanningRaw, fromMonthStr, toMonthStr, onlyFuture]);

	return data;
};
