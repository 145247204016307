import moment from "moment";
import React from "react";
import { DateInput } from "semantic-ui-calendar-react";
import { Form } from "semantic-ui-react";
import { DATEFORMATFRIENDLY } from "../../constants";

export const DatePicker = ({ onChange, value, disabled, label, min, max, ...rest }) => {
	return disabled ? (
		<Form.Input label={label} disabled value={value.format(DATEFORMATFRIENDLY)} />
	) : (
		<DateInput
			label={label}
			animation="none"
			minDate={min ? min.format(DATEFORMATFRIENDLY) : null}
			maxDate={max ? max.format(DATEFORMATFRIENDLY) : null}
			value={value && value.format(DATEFORMATFRIENDLY)}
			onChange={(e, { value }) => {
				onChange(value ? moment(value, DATEFORMATFRIENDLY) : null);
			}}
			dateFormat={DATEFORMATFRIENDLY}
			localization="nl"
			{...rest}
		/>
	);
};
