import moment from "moment";

import { readFile } from "../../../reader";
import { amountTypes, DATEFORMAT } from "../../../constants";
import { InvoiceLineType } from "../configs";
import { getInvoiceForFamily } from "./getInvoiceForFamily";

const COLUMNIDX = {
	DATE: 0,
	DAYOFWEEK: 1,
	NANNYID: 2,
	NANNYNAME: 3,
	CUSTOMERID: 4,
	FAMILY: 5,
	LASTMINUTE: 6,
	NEXTINVOICE: 7,
	REGISTRATIONFEE: 8,
	COMMENTS: 9,
	CONFIRMED: 10,
	ENTERDATE: 11,
	LAST: 12
};

const columns = [
	{ index: COLUMNIDX.DATE, required: false, upper: false },
	{ index: COLUMNIDX.DAYOFWEEK, required: false, upper: false },
	{ index: COLUMNIDX.NANNYID, required: false, upper: false },
	{ index: COLUMNIDX.NANNYNAME, required: false, upper: false },
	{ index: COLUMNIDX.CUSTOMERID, required: true, upper: true },
	{ index: COLUMNIDX.FAMILY, required: false, upper: false },
	{ index: COLUMNIDX.LASTMINUTE, required: false, upper: false },
	{ index: COLUMNIDX.NEXTINVOICE, required: false, upper: false },
	{ index: COLUMNIDX.REGISTRATIONFEE, required: false, upper: false },
	{ index: COLUMNIDX.COMMENTS, required: false, upper: false },
	{ index: COLUMNIDX.CONFIRMED, required: false, upper: false },
	{ index: COLUMNIDX.ENTERDATE, required: false, upper: false }
];

export const readInvoiceInputFile = (fileStr, reject, tariffs, onlyFamilies) => {
	let invoices = {};
	const success = readFile(fileStr, columns, reject, (lineArray) => {
		let familyId = lineArray[COLUMNIDX.CUSTOMERID];
		if (familyId.substring(0, 2) !== "AN") {
			console.log("FamilyId incorrect: " + familyId);
			return;
		}

		if (onlyFamilies?.length > 0 && !onlyFamilies.includes(familyId)) {
			return;
		}

		if (lineArray[COLUMNIDX.REGISTRATIONFEE] && lineArray[COLUMNIDX.REGISTRATIONFEE] !== "") {
			if (tariffs && tariffs.length > 0) {
				const tariff = tariffs[tariffs.length - 1];
				const invoice = getInvoiceForFamily(invoices, familyId, true);
				invoice.lines.push({
					amount: tariff.amounts[amountTypes.RegistrationFee],
					type: InvoiceLineType.RegistrationFee,
					tariffId: tariff.tariffId,
					description: "Inschrijfkosten"
				});
				console.log("Registration fee added: " + familyId);
			}
		}

		const date = moment(lineArray[COLUMNIDX.DATE], ["DD-MM-YY", "DD-MM-YYYY", "YYYY-MM-DD"]);
		if (!date.isValid()) {
			console.log("Date incorrect: " + lineArray[COLUMNIDX.DATE]);
			return;
		}

		const line = {
			dateStr: date.format(DATEFORMAT)
		};

		if (lineArray[COLUMNIDX.LASTMINUTE] && lineArray[COLUMNIDX.LASTMINUTE] !== "") {
			line.lastMinute = true;
		}

		const invoice = getInvoiceForFamily(invoices, familyId, true);
		invoice.lines.push(line);
	});

	if (!success) {
		return null;
	}

	return invoices;
};
