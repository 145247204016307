import { amountTypes } from "../../../constants";
import { InvoiceLineType } from "../configs";
import { getInvoiceForFamily } from "./getInvoiceForFamily";

export const addUnusedFlexToInvoice = (
	invoices,
	familyId,
	flexSubscriptions,
	tariffs,
	monthMoment
) => {
	if (!(familyId in flexSubscriptions)) return false;
	if (flexSubscriptions[familyId].usedOn) return false;

	const { tariffId } = flexSubscriptions[familyId];
	const tariff = tariffs[tariffId];

	const line = {
		description: `Dienst flex-abonnement ${monthMoment.format("MMMM")} (niet opgenomen)`,
		amount: tariff.amounts[amountTypes.SubscriptionCost],
		type: InvoiceLineType.FlexNotUsed,
		tariffId
	};

	const addToInvoice = getInvoiceForFamily(invoices, familyId, true);
	addToInvoice.lines.push(line);

	return true;
};
