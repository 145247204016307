import { Form, Label } from "semantic-ui-react";
import React, { useCallback, useMemo } from "react";

import "firebase/auth";
import "firebase/firestore";
import { useNannies } from "../../hooks/useNannies";
import {
	getDienstNannyStatusLabel,
	getNannyStatusColor,
	getNannyStatusFontColor
} from "../../constants";
import styles from "./AssignNannyGroup.module.css";

const nannySearch = (options, query) => {
	return options.filter((opt) => opt.searchText.includes(query.toLowerCase()));
};

export const AssignNannyGroup = ({
	knownNannies,
	reactionsByNanny,
	nanny,
	setNanny,
	disabled,
	clearable
}) => {
	const nannies = useNannies();
	const nannysById = useMemo(
		() => nannies?.reduce((obj, item) => ((obj[item.id] = item), obj), {}),
		[nannies]
	);
	const knownNanniesById = useMemo(
		() => knownNannies?.reduce((obj, item) => ((obj[item.id] = item), obj), {}),
		[knownNannies]
	);

	const nannyOptions = useMemo(() => {
		let options = [];
		for (let i = 0; i < nannies.length; i++) {
			const nanny = nannies[i];
			const reaction = reactionsByNanny?.[nanny.id];

			let searchText = `${nanny.name} (${nanny.id})`.toLowerCase();
			let text = [`${nanny.name} (${nanny.id})`];
			let extraContent = [];

			// Check if nanny exists in knownNannies
			if (knownNanniesById?.[nanny.id]) {
				extraContent.push(
					<Label className={styles.knownNannyLabel}>Bekend bij gezin</Label>
				);

				searchText = searchText + " (bekend bij gezin)";
			}

			if (reaction) {
				extraContent.push(
					<Label
						style={{
							backgroundColor: getNannyStatusColor(reaction.status),
							color: getNannyStatusFontColor(reaction.status),
							padding: "4px"
						}}
					>
						{getDienstNannyStatusLabel(reaction.status)}
					</Label>
				);

				searchText = searchText + " " + getDienstNannyStatusLabel(reaction.status);
			}

			let content = (
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between"
					}}
				>
					{text}
					<div>{extraContent}</div>
				</div>
			);

			options.push({
				key: nanny.id,
				value: nanny.id,
				text: content,
				searchText
			});
		}

		options.sort((a, b) => {
			if (knownNanniesById) {
				if (knownNanniesById[a.value] && !knownNanniesById[b.value]) return -1;
				if (!knownNanniesById[a.value] && knownNanniesById[b.value]) return 1;
			}

			const aName = nannysById[a.value].name;
			const bName = nannysById[b.value].name;
			return aName.localeCompare(bName);
		});

		return options;
	}, [knownNanniesById, nannies, nannysById, reactionsByNanny]);

	return (
		<Form.Dropdown
			label="Nanny om toe te wijzen"
			placeholder="Selecteer nanny..."
			fluid
			search={nannySearch}
			selection
			value={nanny}
			onChange={(e, { value }) => setNanny(value)}
			options={nannyOptions}
			disabled={disabled}
			clearable={clearable}
		/>
	);
};
