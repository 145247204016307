import { DATEFORMAT } from "../../../constants";

export const removeCreditsForMonth = (credits, monthMoment, onlyFamilies) => {
	if (!credits) return;

	const lastDate = monthMoment.clone().endOf("month");
	const lastMonthDateStr = lastDate.format(DATEFORMAT);
	const firstMonthDateStr = monthMoment.clone().startOf("month").format(DATEFORMAT);

	// Remove credits for this month if any
	for (const familyId in credits) {
		if (onlyFamilies.length > 0 && !onlyFamilies.includes(familyId)) {
			// SKIP
			continue;
		}

		let creditsAfterRemove = [];
		for (let i = 0; i < credits[familyId].length; i++) {
			const { dateStr, usedOn } = credits[familyId][i];
			if (dateStr >= firstMonthDateStr && dateStr <= lastMonthDateStr) {
				// Credit for this month was already added in previous run, remove (will be added again later)
				continue;
			}

			if (usedOn && usedOn >= firstMonthDateStr && usedOn <= lastMonthDateStr) {
				creditsAfterRemove.push({ ...credits[familyId][i], usedOn: null });
			} else {
				creditsAfterRemove.push(credits[familyId][i]);
			}
		}
		credits[familyId] = creditsAfterRemove;
	}
};
