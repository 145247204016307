import moment from "moment";
import {
	amountTypes,
	CreditStatus,
	DATEFORMAT,
	DATEFORMATFRIENDLY,
	getFriendlyDateFromDateStr,
	tariffTypes
} from "../../../constants";
import { InvoiceLineType } from "../configs";

export const applyInvoiceLine = (
	line,
	familyId,
	tariffs,
	flexSubscriptions,
	newCredits,
	invoice,
	monthMoment
) => {
	if (!line.dateStr) return false;

	const date = moment(line.dateStr, DATEFORMAT);

	const tariff = tariffs[line.tariffId];
	if (!tariff) return false;

	let withinFlex = false;
	if (tariff.type === tariffTypes.Flex) {
		if (familyId in flexSubscriptions && !flexSubscriptions[familyId].usedOn) {
			withinFlex = true;
			flexSubscriptions[familyId].usedOn = line.dateStr;
		}
	} else if (tariff.type === tariffTypes.Fixed) {
		if (!newCredits[familyId]) {
			console.log("Tariff fixed but no days: " + familyId);
			invoice.lines = [];
			invoice.error = "Vast tarief zonder dagen";
			return false;
		}
	}

	// Check credits to see if fixed day or valid credit left
	let fixedDay = -1;
	let credit = -1;
	if (newCredits[familyId]) {
		for (let c = 0; c < newCredits[familyId].length; c++) {
			const { dateStr, validUntilDateStr, status } = newCredits[familyId][c];
			if (dateStr > line.dateStr) break;
			if (validUntilDateStr < line.dateStr) continue;
			if (status !== CreditStatus.Available) continue;

			if (credit < 0) {
				credit = c;
			}

			if (newCredits[familyId][c].dateStr === line.dateStr) {
				fixedDay = c;
			}
		}
	}

	if (line.lastMinute) {
		// Last minute
		line.amount = tariff.amounts[amountTypes.LastMinute];
		line.description = "Last-minute dienst";
		line.type = InvoiceLineType.LastMinute;
	} else if (withinFlex) {
		// Flex subscription
		line.amount = tariff.amounts[amountTypes.SubscriptionCost];
		line.description = `Dienst flex-abonnement ${monthMoment.format("MMMM")}`;
		line.type = InvoiceLineType.FlexAbboDienst;
	} else if (fixedDay >= 0) {
		// Fixed day
		line.amount = tariff.amounts[amountTypes.PerFixedDay];
		line.description = "Vaste dienst";
		line.type = InvoiceLineType.SubscriptionDienst;

		// Remove from credits
		newCredits[familyId].splice(fixedDay, 1);
	} else if (credit >= 0) {
		// Use old, valid credit
		line.amount = 0;
		line.description = `Enkele dienst, tegoed gebruikt van ${getFriendlyDateFromDateStr(
			newCredits[familyId][credit].dateStr
		)}`;
		line.type = InvoiceLineType.CreditUsed;

		newCredits[familyId][credit].status = CreditStatus.Used;
		newCredits[familyId][credit].usedOn = line.dateStr;
	} else {
		// No credit left, pay up
		line.amount = tariff.amounts[amountTypes.Single];
		line.description = "Enkele dienst";
		line.type = InvoiceLineType.ExtraDienst;
	}

	line.date = date.format(DATEFORMATFRIENDLY);
	line.dayOfWeek = date.format("dddd");
	line.tariffId = tariff.tariffId;

	return true;
};
