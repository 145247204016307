import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

import "semantic-ui-css/semantic.min.css";
import "./index.css";

import store from "./redux";
import { PrivateRoute, PublicRoute } from "./components/Routes";
import Home from "./components/Home";
import Login from "./components/Login";
import Privacy from "./components/Privacy";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import NewInvoice from "./components/NewInvoice";

import moment from "moment";
import "moment/locale/nl"; // without this line it didn't work
moment.locale("nl");

let ISTEST = false;
let ENABLEVASTEPLANNING = true;

// Initialize Firebase
var firebaseConfig;
if (ISTEST) {
	firebaseConfig = {
		apiKey: "AIzaSyDLRNa0bqdIDDpztcT5WBGufCHETZjYpXM",
		authDomain: "amsternannies-test.firebaseapp.com",
		databaseURL: "https://amsternannies-test.firebaseio.com",
		projectId: "amsternannies-test",
		storageBucket: "amsternannies-test.appspot.com",
		messagingSenderId: "832280987270",
		appId: "1:832280987270:web:1289844a718843417d7307"
	};
} else {
	firebaseConfig = {
		apiKey: "AIzaSyBu1a4YDjNJ-oN5frPdnvDGaI2Ybzv7QyM",
		authDomain: "amsternannies-173cd.firebaseapp.com",
		databaseURL: "https://amsternannies-173cd.firebaseio.com",
		projectId: "amsternannies-173cd",
		storageBucket: "amsternannies-173cd.appspot.com",
		messagingSenderId: "985626870986",
		appId: "1:985626870986:web:5efc1878212b27a9b92033",
		measurementId: "G-JVEGH9XNN0"
	};
}

export { ISTEST, ENABLEVASTEPLANNING };

firebase.initializeApp(firebaseConfig);

// Initialize Cloud Functions through Firebase
var functions = firebase.functions();
var storage = firebase.storage();

const App = () => (
	<Router>
		<Provider store={store}>
			<Switch>
				<PublicRoute exact path="/login" component={Login} />
				<PrivateRoute exact path="/home" component={Home} />
				<PrivateRoute exact path="/invoice" component={NewInvoice} />
				<Route exact path="/privacy" component={Privacy} />
				<Redirect from="*" to="/home" />
			</Switch>
		</Provider>
	</Router>
);

ReactDOM.render(<App />, document.getElementById("root"));
