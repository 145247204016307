import { CreditStatus } from "../../../constants";

export const setExpiredCredits = (familyId, newCredits, lastMonthDateStr) => {
	if (!newCredits[familyId]) return false;

	// Set expired credits
	for (let c = 0; c < newCredits[familyId].length; c++) {
		const { validUntilDateStr, status } = newCredits[familyId][c];
		if (validUntilDateStr > lastMonthDateStr) break;
		if (status === CreditStatus.Used) continue;

		newCredits[familyId][c].status = CreditStatus.Expired;
	}
};
